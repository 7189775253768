import { ApiToken } from './api-token'
import { FormulaParser } from './formula-parser'
import { TemplateCustomToken } from './template-custom-token.admin'
import { Token } from './token'

export const createNameValidation = (token: Token, scopeNames: Set<string>) => {
  if (Token.isTokenStatic(token)) {
    return (value: string | null) => {
      if (!value) return 'Missing name'
      if (!scopeNames.has(value)) return `Unknown token name: ${value}`
      return null
    }
  }
  if (Token.isSignature(token)) {
    return (value: string | null) => (value?.trim() ? 'Must be empty' : null)
  }
  return (value: string | null) => {
    if (!value?.trim()) return null
    if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(value)) {
      return 'Invalid name (must start with a letter or underscore, followed by letters, numbers, or underscores)'
    }
    if (FormulaParser.isFunctionName(value)) return 'Invalid name (reserved function name)'
    if (FormulaParser.isConstName(value)) return 'Invalid name (reserved constant name)'
    if (scopeNames.has(value)) return 'Invalid name (token name already exists)'
    return null
  }
}

export const validateConditions = (token: Token, scopeNames: Set<string>) => {
  let conditions
  try {
    conditions = Token.getConditions(token, undefined, true)
    if (!conditions) return
  } catch (e) {
    return 'Unable to parse conditions: ' + (e as Error)?.message
  }

  const notInScope = (name: string) => !scopeNames.has(name)
  const variables = Object.values(conditions).flatMap((exp) => exp.variables())
  for (const variable of variables) {
    if (notInScope(variable)) return `Unknown token: ${variable}`
  }
  for (const [name, expression] of Object.entries(conditions)) {
    try {
      expression.simplify()
    } catch (e) {
      return (
        `${token?.label ?? token?.name}: Unable to evaluate property ${name}. ` +
        (e as Error)?.message
      )
    }
  }
}

export const validateRole = (token: Token) => {
  if (!Token.isRole(token.role)) return 'Token must have a valid role'
  if (Token.isSignature(token)) {
    if ([Token.Role.API, Token.Role.ADMIN].includes(token.role)) {
      return 'Invalid role for signature token'
    }
  }
}

export const validateToken = (token: Token, scopeNames: Set<string>) => {
  const validateName = createNameValidation(token, scopeNames)
  return (
    validateName(token.name ?? null) ?? validateRole(token) ?? validateConditions(token, scopeNames)
  )
}

export const validateTokens = (
  tokens: Token[],
  apiTokenMap: Record<string, ApiToken>,
): Record<string, string> | undefined => {
  const errorMap = tokens.reduce<Record<string, string>>((map, token) => {
    // validate token
    const namesInScope = [
      ...Object.keys(apiTokenMap),
      ...TemplateCustomToken.PERSONAL_TOKEN_NAMES,
      ...Object.keys(Token.getTokenScopeMap(token, tokens)),
    ]
    const error = validateToken(token, new Set(namesInScope))
    return error ? { ...map, [token.token_id]: error } : map
  }, {})

  return Object.keys(errorMap).length ? errorMap : undefined
}
